const dirty = (context, ids) => {
    ids.forEach(id => {
        context.commit(`${id}/setDirty`, {
            value: true,
        }, {
            root: true,
        });
    });
};

export default (context, payload) => {
    const id = context.getters.moduleId;
    const ids = [`${id}_contactPhone_phone`, `${id}_contactEmail_input-text`, `${id}_contactAddress`];
    const phone = context.rootState[`${id}_contactPhone_phone`];
    const email = context.rootState[`${id}_contactEmail_input-text`];
    const address = context.rootState[`${id}_contactAddress`];

    const completeAddress = context.rootState[`${id}_contactAddress_address1`].value
    && context.rootState[`${id}_contactAddress_city`].value
    && context.rootState[`${id}_contactAddress_state`].value
    && context.rootState[`${id}_contactAddress_zip`].value;

    if (payload.targetId === `${id}_continue` ) {
        dirty(context, ids);
    }

    if (!phone.flags.dirty || !email.flags.dirty || !address.flags.dirty) {
        // don't validate unless all are dirty
        return;
    }

    const missingContactInfo = !completeAddress && !phone.value && !email.value;

    context.commit(`${id}_contactEmail_input-text/setValidityTestValue`, {
        validityTest: 'missingContactInfo',
        validityTestValue: missingContactInfo,
    }, {
        root: true,
    });
    context.commit(`${id}_contactAddress/setValidityTestValue`, {
        validityTest: 'missingContactInfo',
        validityTestValue: missingContactInfo,
    }, {
        root: true,
    });
    context.commit(`${id}_contactPhone_phone/setValidityTestValue`, {
        validityTest: 'missingContactInfo',
        validityTestValue: missingContactInfo,
    }, {
        root: true,
    });


    context.dispatch('validate', {
        targetId: `${id}_contactPhone`,
        validation: {},
    }, {
        root: true,
    });
    context.dispatch('validate', {
        targetId: `${id}_contactEmail_input-text`,
        validation: {},
    }, {
        root: true,
    });
    // Needed parent email component to get rid of "global" errors in error container
    context.dispatch('validate', {
        targetId: `${id}_contactEmail`,
        validation: {},
    }, {
        root: true,
    });
    context.dispatch('validate', {
        targetId: `${id}_contactAddress`,
        validation: {},
    }, {
        root: true,
    });

};
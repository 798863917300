import decodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/decodeDotcmsCookie.js';

export default (context, payload) => {
    const allCookies = payload.oneLni.storage.cookie.get();
    const formData = Object.entries(allCookies).reduce((collection, [name, value]) => {
        if (name.startsWith('retaliationComplaint')) {
            return [...collection, ...decodeDotcmsCookie(value)];
        }
        return collection;

    }, []);
    if (formData.length) {
        context.commit('setAttribute', {
            id: payload.id,
            attribute: 'formData',
            value: formData,
        }, {
            root: true,
        });
    }
};
<template>
    <form
        class="lni-u-mt3 lni-c-retaliation-complaint flow lni-u-reading-width--max"
        @input="onFormChange($event)"
        @change="onFormChange($event)"
    >
        <details>
            <summary class="lni-u-type--bold">
                {{ text.legalInformationLabel }}
            </summary>
            <lni-html-element
                id="more-information"
                :htmlString="text.legalInformation || ''"
            />
        </details>
        <p>
            {{ text.requiredFieldsMessage }}
        </p>
        <fieldset class="flow lni-u-form-width--base">
            <legend>
                {{ text['contactFieldset.legend'] }}
            </legend>
            <p> {{ text['contactFieldset.helperText'] }}</p>
            <div class="lni-u-flex lni-u-gap1 lni-u-items-start  lni-u-form-width--base">
                <lni-select
                    class="--custom-width-5"
                    v-bind="getField('contactTitle')"
                    :fullWidth="false"
                />
                <lni-input-text
                    required="true"
                    v-bind="getField('contactName')"
                    :fullWidth="true"
                />
            </div>
            <lni-input-phone
                v-bind="getField('contactPhone')"
                :inputAction="`${id}/validateContactInfo`"
                :hasExtension="false"
            />
            <lni-radios
                class="lni-u-mt1"
                v-bind="getField('contactPhoneType')"
                :isColumn="false"
                :hasInlineMessages="false"
                @click="toggleTextPermission(event)"
            />
            <transition name="slide-from-top">
                <lni-radios
                    v-show="hasCellPhone"
                    class="lni-u-mt1"
                    :isColumn="false"
                    :hasInlineMessages="false"
                    v-bind="getField('contactTextPermission')"
                />
            </transition>
            <lni-input-email
                v-bind="getField('contactEmail')"
                :inputAction="`${id}/validateContactInfo`"
            />
            <lni-input-address
                v-bind="getField('contactAddress')"
                :filled="true"
                :inputAction="`${id}/validateContactInfo`"
            />
            <lni-select
                class="lni-u-mt0"
                v-bind="getField('contactCounty')"
            />
            <lni-input-textarea
                v-bind="getField('contactPreference')"
                :limit="255"
                :fullWidth="true"
            />
        </fieldset>
        <fieldset class="flow lni-u-form-width--base">
            <legend>{{ text.employerInformation }}</legend>
            <lni-input-text v-bind="getField('employerName')" />
            <lni-input-phone v-bind="getField('employerPhone')" />
            <lni-input-address
                v-bind="getField('employerAddress')"
                :filled="true"
            />
        </fieldset>
        <fieldset class="flow lni-u-form-width--base">
            <legend>{{ text.jobInformation }}</legend>
            <lni-input-text v-bind="getField('jobTitle')" />
            <lni-input-date v-bind="getField('dateHired')" />
            <lni-radios
                v-bind="getField('isTerminated')"
                :isColumn="false"
            />
            <transition name="slide-from-top">
                <lni-input-date
                    v-show="isTerminated"
                    v-bind="getField('dateTerminated')"
                />
            </transition>
            <lni-radios
                v-bind="getField('isResigned')"
                :isColumn="false"
            />
            <transition name="slide-from-top">
                <lni-input-date
                    v-show="isResigned"
                    v-bind="getField('dateResigned')"
                />
            </transition>
        </fieldset>
        <lni-input-date v-bind="getField('retaliationDate')" />
        <lni-input-textarea
            v-bind="getField('retaliationDescription')"
            :limit="4000"
            :fullWidth="true"
        >
            <template #helperText>
                <p>
                    {{ getField('retaliationDescription').helperText }}
                </p>
            </template>
        </lni-input-textarea>
        <lni-input-textarea
            v-bind="getField('retaliationReason')"
            :limit="4000"
            :fullWidth="true"
        />
        <lni-radios
            v-bind="getField('hasContactedOtherAgency')"
            :isColumn="false"
        />
        <transition name="slide-from-top">
            <div
                v-show="hasContactedOtherAgency"
                class="flow"
            >
                <lni-input-text v-bind="getField('otherAgency')" />
                <lni-input-date
                    v-bind="getField('otherAgencyContactDate')"
                />
                <lni-input-textarea
                    v-bind="getField('otherAgencyResults')"
                    :limit="500"
                    :fullWidth="true"
                />
            </div>
        </transition>

        <lni-checkbox v-bind="getField('attestation')" />
        <lni-button
            :id="`${id}_continue`"
            ref="button"
            text="Submit"
            type="button"
            class="lni-u-mt3"
            errorContainerId="retaliation-complaint-errors"
            :clickAction="[`${id}/validateContactInfo`,'validateForm', `${id}/submit`]"
        />
        <lni-error-container
            id="retaliation-complaint-errors"
            class="lni-u-mt2"
            heading="Before we submit your complaint"
            :errorsCollectedFrom="[
                `${id}_contactTitle`,
                `${id}_contactName`,
                `${id}_contactEmail`,
                `${id}_contactPhone`,
                `${id}_contactAddress`,
                `${id}_contactPreference`,
                `${id}_employerName`,
                `${id}_isTerminated`,
                `${id}_isResigned`,
                `${id}_retaliationDescription`,
                `${id}_hasContactedOtherAgency`,
                `${id}_attestation`,
            ]"
        />
        <lni-spinner
            v-if="isSubmitting"
            id="retaliation-complaint-spinner"
        />
    </form>
</template>
<script>
import {
    fields,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/data/formModel.js';

export default {
    name: 'LniRetaliationComplaint',
    computed: {
        hasCellPhone() {
            return this.$store.state[`${this.id}_contactPhoneType`]?.value === 'Yes';
        },
        isTerminated() {
            return this.$store.state[`${this.id}_isTerminated`]?.value === 'Yes';
        },
        isResigned() {
            return this.$store.state[`${this.id}_isResigned`]?.value === 'Yes';
        },
        hasContactedOtherAgency() {
            return this.$store.state[`${this.id}_hasContactedOtherAgency`]?.value === 'Yes';
        },
        isSubmitting() {
            return this.$store.state[this.id].isSubmitting;
        }
    },
    mounted() {
        this.$store.dispatch(`${this.id}/hydrateStateFromCookie`, {
            id: this.id,
            oneLni: this.$oneLni,
        }).then(() => this.dispatchEvent(`${this.id}/syncUi`));

        this.addCustomValidations();
    },
    methods: {
        getField(shortId) {
            const fieldModel = fields(this.text, this.counties).find(field => field.id === shortId);
            const props = {
                ...fieldModel,
                id: `${this.id}_${shortId}`,
                fullWidth: true,
            };
            return props;
        },
        onFormChange($event) {
            let targetId = $event.target.id;
            if ($event.target.type === 'radio') {
                // The id of the lni-radios parent
                targetId = targetId.split('_').slice(0, -1).join('_');
            }
            if ($event.target.classList.contains('flatpickr-input')) {
                targetId = $event.target.parentElement.parentElement.parentElement.id;
            }
            this.$store.dispatch(`${this.id}/syncStore`, {
                targetId,
                sourceId: this.id,
                oneLni: this.$oneLni,
            });
        },
        addCustomValidations() {
            this.$store.commit(`${this.id}_contactEmail_input-text/addValidityTest`, {
                validityTest: 'missingContactInfo',
                inline: () => 'Please provide either email, phone, or address.',
                global: () => 'Missing email. Please provide either email, phone, or address.',
            });
            this.$store.commit(`${this.id}_contactPhone_phone/addValidityTest`, {
                validityTest: 'missingContactInfo',
                inline: () => 'Please provide either email, phone, or address.',
                global: () => 'Missing phone. Please provide either email, phone, or address.',
            });
            this.$store.commit(`${this.id}_contactAddress/addValidityTest`, {
                validityTest: 'missingContactInfo',
                inline: () => 'Please provide either email, phone, or address.',
                global: () => 'Missing address. Please provide either email, phone, or address.',
            });
        },
    },
}; </script>
<style>
.flow > * + * {
    margin-top: 1.5rem;
}

.lni-c-retaliation-complaint legend {
    font-weight: bold;
    font-size: 1.5rem;
}

.lni-c-retaliation-complaint .lni-c-radio__legend, .lni-c-input-date__wrapper legend{
    font-weight: normal;
    font-size: 1.25rem;
}
</style>
export const formFields = [
    {
        id: 'consultation-request_input-text',
        label: 'What can we help you with?',
    },
    {
        id: 'contact-person',
        label: 'Contact person',
    },
    {
        id: 'job-title',
        label: 'Job title',
    },
    {
        id: 'contact-phone_phone',
        label: 'Phone',
    },
    {
        id: 'contact-phone_ext',
        label: 'Ext.',
    },
    {
        id: 'contact-email_input-text',
        label: 'Contact Email',
    },
    {
        id: 'contact-preference_input-text',
        label: 'Which contact method and time of day do you prefer?',
    },
    {
        id: 'kind-of-work_input-text',
        label: 'What kind of work do you do?',
    },
    {
        id: 'company-name',
        label: 'Company name',
    },
    {
        id: 'address_address1',
        label: 'Address 1',
    },
    {
        id: 'address_address2',
        label: 'Address 2',
    },
    {
        id: 'address_city',
        label: 'City',
    },
    {
        id: 'address_state',
        label: 'State',
        value: 'WA',
    },
    {
        id: 'address_zip',
        label: 'Zip',
    },
    {
        id: 'county',
        label: 'County',
    },
    {
        id: 'ubi',
        label: 'WA UBI no.',
    },
    {
        id: 'account-id',
        label: 'L&I Account ID',
    },
];
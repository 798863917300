<template>
    <div class="lni-c-simbic-calculator__container">
        <fieldset>
            <legend class="lni-u-type--xxs lni-u-line-height--tight">
                Date Bill Received (Mailing date + 5 days)
                or Claim Allowance Date
            </legend>
            <div class="lni-c-simbic-calculator__row-wrapper">
                <lni-input-date
                    :id="id + '_billing-date'"
                    class="lni-c-simbic-calculator__input lni-u-block lni-u-mt1 lni-u-mr2"
                    pattern="[0-9]"
                    :customWidth="11"
                    :filled="true"
                    labelText="Date Bill Received"
                    :required="true"
                    :dense="true"
                    :labelBefore="true"
                    :changeAction="`${id}/resetCalculatedValues`"
                    helperText=""
                    :hasInlineMessages="false"></lni-input-date>
                <lni-input-text
                    :id="id + '_balance-due'"
                    class="lni-c-simbic-calculator__balance-due-input lni-c-simbic-calculator__input lni-u-mr2"
                    type="currency"
                    pattern="[0-9\.]+"
                    :maxlength="20"
                    labelText="Balance Due"
                    :customWidth="11"
                    :dense="true"
                    :filled="true"
                    :labelBefore="true"
                    :required="true"
                    :changeAction="`${id}/resetCalculatedValues`"
                    :hasInlineMessages="false">
                    <span slot="prefix">
                        $
                    </span>
                </lni-input-text>
                <lni-input-display
                    :id="id + '_accrual-date'"
                    class="lni-c-simbic-calculator__accrual-date lni-c-simbic-calculator__input lni-u-mt2 lni-u-mb0"
                    labelText="Interest Accrual Date"
                    :dense="true"></lni-input-display>
            </div>
        </fieldset>
        <h2 class="lni-u-type--bold lni-u-type--xl lni-u-mt3">
            Payments
        </h2>
        <fieldset
            v-for="(paymentField, index) in paymentFields"
            :key="`payment${index + 1}`"
            class="lni-c-simbic-calculator__payment-fieldset lni-u-block">
            <div class="lni-c-simbic-calculator__row-wrapper lni-u-mb1">
                <lni-input-date
                    :id="`${id}_payment-date-${index + 1}`"
                    class="lni-c-simbic-calculator__input lni-u-block lni-u-mt0 lni-u-mr2"
                    pattern="[0-9]"
                    :customWidth="11"
                    :filled="true"
                    :labelText="`Date of Payment ${index + 1}`"
                    :required="index + 1 === 1"
                    :dense="true"
                    :labelBefore="true"
                    :changeAction="`${id}/resetCalculatedValues`"
                    helperText=""
                    :hasInlineMessages="false"></lni-input-date>
                <lni-input-text
                    :id="`${id}_payment-amount-${index + 1}`"
                    class="lni-c-simbic-calculator__payment-input lni-c-simbic-calculator__input lni-u-mr2"
                    type="currency"
                    pattern="[0-9\.]+"
                    :maxlength="20"
                    :labelText="`Payment ${index + 1} Amount`"
                    :customWidth="11"
                    :dense="true"
                    :filled="true"
                    :labelBefore="true"
                    :required="index + 1 === 1"
                    :changeAction="`${id}/resetCalculatedValues`"
                    :hasInlineMessages="false">
                    <span slot="prefix">
                        $
                    </span>
                </lni-input-text>
                <lni-input-display
                    :id="`${id}_interest-${index + 1}`"
                    class="lni-c-simbic-calculator__input lni-u-mt2 lni-u-mb0"
                    labelText="Interest Accrued"
                    dense="true"></lni-input-display>
            </div>
        </fieldset>
        <div class="lni-c-simbic-calculator__add-row-controls lni-u-mt2">
            <lni-button
                id="addPaymentRowButton"
                text="Add Another Payment"
                :clickAction="`${id}/addPaymentRow`">
            </lni-button>
            <lni-button
                v-if="paymentFields.length > 1"
                id="deletePaymentRowButton"
                class="lni-u-ml1"
                text="Delete"
                type="alternate"
                :clickAction="`${id}/removePaymentRow`">
            </lni-button>
        </div>


        <div class="lni-u-flex lni-u-flex-wrap lni-u-mt3 lni-u-pt3 lni-u-bt lni-u-b--subtle">
            <lni-input-display
                :id="id + '_remaining-principal'"
                labelText="Remaining Principal Owed"
                class="lni-u-text-center lni-u-mb1 lni-u-mr2"></lni-input-display>
            <lni-input-display
                :id="id + '_balance-owed'"
                labelText="Remaining Balance Owed"
                class="lni-u-text-center lni-u-mb1"></lni-input-display>
        </div>

        <lni-error-container
            id="simbic-calculator-error-container"
            class="lni-u-mt3"
            heading="Before we submit this form:"></lni-error-container>
        <div class="lni-c-simbic-calculator__actions-wrapper lni-u-flex lni-u-mt2">
            <lni-button
                :id="id + '_calculate-button'"
                errorContainerId="simbic-calculator-error-container"
                text="Calculate"
                class="lni-c-simbic-calculator__calculate-button lni-c-button"
                @click.native="calculateClick"></lni-button>
            <lni-button
                :id="id + '_reset-button'"
                text="Reset"
                type="alternate"
                errorContainerId="simbic-calculator-error-container"
                :clickAction="`${id}/reset`"
                class="lni-u-ml1"></lni-button>
        </div>
    </div>
</template>

<script>
//TODO: computed errors collected from

export default {
    name: 'lni-simbic-calculator',
    mounted() {
        this.$store.commit('setAttribute', {
            id: `simbic-calculator-error-container`,
            attribute: 'errorsCollectedFrom',
            value: [
                `${this.id}_billing-date`,
                `${this.id}_balance-due`,
                ...this.$store.getters[`${this.id}/paymentFieldIds`],

            ],
        });
        this.$store.dispatch(`${this.id}/reset`, {
            sourceId: this.id,
        });
    },
    methods: {
        calculateClick() {
            const payload = {
                sourceId: this.id,
                targetId: `${this.id}_calculate-button`,
                validation: {
                    errorsCollectedBy: this.$store.state[this.id].errorsCollectedBy,
                },
            };
            const self = this;
            this.$store.dispatch('validateForm', payload)
                .then(() => {
                    if (self.$store.getters['simbic-calculator-error-container/isValid']) {
                        this.$store.dispatch(`${this.id}/calculate`, payload);
                    } else {
                        this.$store.dispatch(`${this.id}/resetCalculatedValues`, payload);
                        setTimeout(() => {
                            self.$scrollTo(`#simbic-calculator-error-container`);
                        }, 0);
                    }
                });
        },
    },
}; </script>
<style lang="scss">
@import 'lni-simbic-calculator';
</style>
const submitForm = context => {
    window.location.href = context.state.sendEmailEndpoint;
};

export default context => {
    const formId = context.getters.moduleId;
    if (context.rootState['retaliation-complaint-errors'].errors < 1) {
        context.commit('setAttribute', {
            id: formId,
            attribute: 'isSubmitting',
            value: true,
        }, {
            root: true,
        });
        submitForm(context);
    }
};
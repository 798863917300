import submit from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/actions/submit.js';
import syncUi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/actions/syncUi.js';
import syncStore from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/actions/syncStore.js';
import persist from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/actions/persist.js';
import hydrateStateFromCookie from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/actions/hydrateStateFromCookie.js';
import counties from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/data/counties.js';
import validateContactInfo from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/actions/validateContactInfo.js';
import {
    text,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/data/text.en';
import {
    fields,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/data/formModel.js';

export default () => ({
    state: {
        text,
        counties,
        sendEmailEndpoint: '',
        senderEmail: '',
        isSubmitting: false,
        formData: fields(text, counties).map(({
            id,
            labelText,
            parentId,
            hide,
        }) => ({
            id,
            label: labelText,
            parentId,
            hide,
        })),
    },
    actions: {
        submit,
        syncUi,
        syncStore,
        persist,
        validateContactInfo,
        hydrateStateFromCookie,
    },
    mutations: {
        UPDATE_FORM_ITEM(state, payload) {
            const itemIndex = state.formData.findIndex(item => item.id === payload.id);
            if (itemIndex === -1) {
                return;
            }
            const newItem = {
                ...state.formData[itemIndex],
                ...payload,
            };
            state.formData.splice(itemIndex, 1, newItem);
        },
    },
});
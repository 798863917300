import encodeDotcmsCookie from '../../../utilities/storage/encodeDotcmsCookie';

const FORM_DATA_COOKIE_PREFIX = 'retaliationComplaint';
const pauseInterval = 300;
const debounce = (func, timeout = pauseInterval) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};
const byteSize = str => new Blob([str]).size;
const limit = 2000; //estimate: the cookie is larger when jscookie encodes it.
const split = arr => {
    const divisor = 2;
    const midIndex = Math.ceil(arr.length / divisor);
    const arr2 = [...arr];
    const arr1 = arr2.splice(0, midIndex);
    return [arr1, arr2];
};
const tooBig = arr => {
    const rawCookie = encodeDotcmsCookie(arr);
    const size = byteSize(rawCookie);
    return size > limit;
};

const chunkArray = (data, collector = [], remaining = []) => {
    if (tooBig(data)) {
        const parts = split(data);
        chunkArray(parts[0], collector, [...parts[1], ...remaining]);
    } else {
        collector.push(data);
        if (remaining.length) {
            chunkArray(remaining, collector);
        }
    }
    return collector;
};

const cookiePackage = formData => chunkArray(formData).map((chunk, index) => ({
    name: `${FORM_DATA_COOKIE_PREFIX}${index + 1}`, // for some reason the zero causes dotcms apache to return 400
    value: encodeDotcmsCookie(chunk),
}));

const saveCookies = async (cookiePackage, oneLni) => {
    const incrementDivisor = 12; // this may do nothing, but it seems to prevent 400 errors from dotcms apache
    for (let index = 0; index < cookiePackage.length; index++) {
        let {
            name, value,
        } = cookiePackage[index];
        await oneLni.storage.cookie.set(
            name,
            value,
            {
                expires: 1 + (index / incrementDivisor),
            },
        );
    }
};

const clearExtraCookies = async (currentCookieCount, oneLni) => {
    const prevCookies = await oneLni.storage.cookie.get();
    const cookiesToBeDeleted = Object.entries(prevCookies)
        .filter(([name]) =>
            parseInt(name.replace(FORM_DATA_COOKIE_PREFIX, '')) > currentCookieCount)
        .map(([name]) => name);
    if (cookiesToBeDeleted.length) {
        for (let index = 0; index < cookiesToBeDeleted.length; index++) {
            await oneLni.storage.cookie.remove(cookiesToBeDeleted[index]);
        }
    }
};

const updateCookies = (cookies, oneLni) => {
    saveCookies(cookies, oneLni).then(() => {
        clearExtraCookies(cookies.length, oneLni);
    });
};

export default (context, payload) => {
    if (context.state.formData.length) {
        const cookies = cookiePackage(context.state.formData);
        debounce(updateCookies(cookies, payload.oneLni));
    } else {
        clearExtraCookies(0, payload.oneLni);
    }
};
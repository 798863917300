<template>
    <form
        class="lni-c-consultation-request lni-u-mt3 lni-u-form-width--base"
        @input="onFormChange($event)"
        @change="onFormChange($event)"
    >
        <h3>Consultation request</h3>
        <lni-input-textarea
            :id="`${id}_consultation-request`"
            ref="consultation-request"
            labelText="What can we help you with?"
            :filled="true"
            :required="true"
            :limit="255"
            :fullWidth="true"
        >
            <template #helperText>
                <span>Examples: identifying safety hazards; written safety plan; safety training; controlling rates and claims</span>
            </template>
        </lni-input-textarea>
        <fieldset class="lni-u-mt3">
            <legend class="lni-u-heading--3">
                Contact information
            </legend>
            <div class="lni-u-flex lni-u-flex-column">
                <lni-input-text
                    :id="`${id}_contact-person`"
                    ref="contact-person"
                    labelText="Contact person"
                    :filled="true"
                    :required="true"
                    :labelBefore="true"
                    :fullWidth="true"
                />
                <lni-input-text
                    :id="`${id}_job-title`"
                    ref="job-title"
                    labelText="Job title"
                    :filled="true"
                    :labelBefore="true"
                    class="lni-u-mt3"
                    :fullWidth="true"
                />
                <lni-input-phone
                    :id="`${id}_contact-phone`"
                    ref="contact-phone"
                    labelText="Contact phone"
                    :isFieldset="false"
                    :filled="true"
                    :required="true"
                    class="lni-u-mt3"
                    :labelBefore="true"
                    :fullWidth="true"
                />
                <lni-input-email
                    :id="`${id}_contact-email`"
                    ref="contact-email"
                    labelText="Contact Email"
                    :filled="true"
                    :required="true"
                    :labelBefore="true"
                    :fullWidth="true"
                />
                <lni-input-textarea
                    :id="`${id}_contact-preference`"
                    ref="contact-preference"
                    labelText="Which contact method and time of day do you prefer?"
                    :filled="true"
                    :limit="255"
                    class="lni-u-mt3"
                    :fullWidth="true"
                >
                    <template #helperText>
                        <div>
&nbsp;
                        </div>
                    </template>
                </lni-input-textarea>
            </div>
        </fieldset>
        <fieldset class="lni-u-mt2">
            <legend class="lni-u-heading--3">
                Company information
            </legend>
            <div class="lni-u-flex lni-u-flex-column">
                <lni-input-textarea
                    :id="`${id}_kind-of-work`"
                    ref="kind-of-work"
                    labelText="What kind of work do you do?"
                    :filled="true"
                    :limit="255"
                    :fullWidth="true"
                >
                    <template #helperText>
                        <div>
                &nbsp;
                        </div>
                    </template>
                </lni-input-textarea>
                <lni-input-text
                    :id="`${id}_company-name`"
                    ref="company-name"
                    labelText="Company name"
                    :filled="true"
                    :required="true"
                    :labelBefore="true"
                    class="lni-u-mt1"
                    :fullWidth="true"
                />

                <lni-input-address
                    :id="`${id}_address`"
                    ref="address"
                    labelText="Location address"
                    :labelBefore="true"
                    :filled="true"
                    class="lni-u-mt3"
                    :fullWidth="true"
                />
                <lni-select
                    :id="`${id}_county`"
                    labelText="County"
                    labelBefore="true"
                    :required="true"
                    :options="counties"
                    class="lni-u-mt0"
                    :fullWidth="true"
                >
                    <template #helperText>
                        <span>County is not needed if you are outside Washington State</span>
                    </template>
                </lni-select>
                <lni-input-text
                    :id="`${id}_ubi`"
                    ref="ubi"
                    v-constrain="upTo9digitNumber"
                    labelText="WA UBI no."
                    :filled="true"
                    :labelBefore="true"
                    class="lni-u-mt2"
                    maxlength="9"
                    pattern="[0-9]{9}"
                    :fullWidth="true"
                >
                    <template #helperText>
                        <span>Numbers only, no special characters</span>
                    </template>
                </lni-input-text>
                <lni-input-text
                    :id="`${id}_account-id`"
                    ref="account-id"
                    v-constrain="upTo8digitNumber"
                    labelText="L&amp;I Account ID"
                    :filled="true"
                    :labelBefore="true"
                    class="lni-u-mt3"
                    pattern="[0-9]{8}"
                    maxlength="8"
                    :fullWidth="true"
                >
                    <template #helperText>
                        <span>Numbers only, no special characters</span>
                    </template>
                </lni-input-text>
            </div>
        </fieldset>
        <lni-button
            :id="`${id}_submit`"
            ref="button"
            text="Submit Request"
            type="button"
            class="lni-u-mt3"
            errorContainerId="consultation-request-errors"
            :clickAction="['validateForm',`${id}/submit`]"
        />
        <lni-error-container
            id="consultation-request-errors"
            class="lni-u-mt2"
            heading="Before we submit your request"
            :errorsCollectedFrom="[
                `${id}_consultation-request`,
                `${id}_contact-person`,
                `${id}_contact-phone`,
                `${id}_contact-email`,
                `${id}_company-name`,
                `${id}_county`,
                `${id}_address`,
                `${id}_ubi`,
                `${id}_account-id`,

            ]"
        />
        <p>
            Contact <a href="mailto:DOSHconsultation@lni.wa.gov">DOSHconsultation@lni.wa.gov</a>
            or call <a href="tel:360-870-2269">360-870-2269</a> if you have problems completing this form.
        </p>
        <div v-if="showModalSpinner">
            <lni-spinner
                :id="`${id}_spinner`"
            />
        </div>
    </form>
</template>
<script>
import constrain from '@gov.wa.lni/framework.one-lni.directives/source/constrain.js';

export default {
    name: 'LniConsultationRequest',
    directives: {
        constrain,
    },
    data() {
        return {
            upTo8digitNumber: /^[0-9]{0,8}$/,
            upTo9digitNumber: /^[0-9]{0,9}$/,
        };
    },
    mounted() {
        this.$store.commit(`${this.id}/INITIALIZE`, {
            oneLni: this.$oneLni,
        });
        this.dispatchEvent(`${this.id}/syncUi`);
        this.addCustomMessages();
    },
    methods: {
        getLabel(id) {
            return this.$store.state[this.getFullId(id)].labelText;
        },
        getFullId(shortId) {
            return `${this.id}_${shortId}`;
        },
        onFormChange($event) {
            this.$store.dispatch(`${this.id}/syncStore`, {
                targetId: $event.target.id,
                sourceId: this.id,
                oneLni: this.$oneLni,
            });
        },
        addCustomMessages() {
            this.$store.commit(`${this.id}_ubi/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state =>
                    `The ${state.labelText} must be 9 digits.`,
                inline: () =>
                    `Please enter a valid 9 digit UBI number`,
            });
            this.$store.commit(`${this.id}_account-id/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state =>
                    `The ${state.labelText} must be 8 digits.`,
                inline: () =>
                    `Please enter a valid 8 digit Account ID`,
            });
        },
    },
}; </script>